import React from 'react';
import { graphql, PageProps } from 'gatsby';

const NotFound: React.FC<PageProps> = () => (
  <main>
    <p>Sorry, page not found!</p>
  </main>
);

export default NotFound;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
